<template>
  <div>
    <button @click="topFunction()" id="myBtn" title="Go to top">
      <i class="fa-solid fa-chevron-up"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "ScrollToTop",
  mounted() {
    window.addEventListener('scroll', this.scrollFunction);
  },
  destroyed() {
    window.addEventListener('scroll', this.scrollFunction);
  },
  methods: {
    scrollFunction() {
      var mybutton = document.getElementById("myBtn");

      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    },
    topFunction() {
      window.scrollTo({
        top: 0,
      })
    }
  }
}
</script>

<style scoped>
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 15px;
  border: none;
  outline: none;
  background-color: rgb(140, 61, 64);
  color: #c18f3b;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
}

#myBtn:hover {
  background-color: #21242b;
}
</style>