<template>
  <v-card tile flat color="#21242b">
    <div class="footer-wrapped">
      <div class="footer-left">
        <div class="logo-footer-wrapped">
          <img src="https://bit.ly/3M0yRIT" height="150px" width="100px" />
          <p class="footer-logo-text mt-5">
            <strong>HARMET & CO</strong> {{ $t("company_highlight") }}
          </p>
        </div>
      </div>

      <div class="footer-center">
        <p class="title-footer-center">Harmet & Co.</p>
        <div class="submenu-footer-center">
          <a href="/expertise/patent" class="submenu mt-4">{{
              $t("expertise_1")
          }}</a>
          <a href="/expertise/trademark" class="submenu">{{
              $t("expertise_2")
          }}</a>
          <a href="/expertise/industrial-design" class="submenu">{{
              $t("expertise_3")
          }}</a>
          <a href="/expertise/copyright" class="submenu">{{
              $t("expertise_4")
          }}</a>
        </div>
      </div>

      <div class="footer-right">
        <p class="title-footer-right">{{ $t("contact_us") }}</p>
        <div class="submenu-footer-right">
          <p class="submenu-right mt-4">
            <span><i class="fas fa-phone-alt mr-2"></i>Call :</span><br />
            <span>+62 21 850 6088</span><br />
            <span>+62 21 8591 6126</span><br />
            <span>+62 21 851 2164</span>
          </p>
          <p class="submenu-right">
            <span><i class="fa-solid fa-envelope-open-text mr-2"></i>Email :</span><br />
            <a href="mailto:info@harmet.id">info@harmet.id</a><br />
            <a href="mailto:ip@harmet.id">ip@harmet.id</a>
          </p>
          <p class="submenu-right">
            <span><i class="fa-solid fa-location-dot mr-2"></i>Address :</span><br />
            <span>Jalan Utan Kayu Raya No.65, Jakarta 13120</span>
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="button-lang-wrapped">
      <button class="button-lang" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
        <flag :iso="entry.flag" v-bind:squared="false" /> &nbsp;{{
            entry.title
        }}
      </button>
    </div> -->

    <hr style="color: white; margin: 0" />
    <div class="footer-bottom text-center">
      <small class="footer-bottom-text m-0 p-0">
        <span class="mr-1">Copyrights</span>
        &copy;
        <span class="ml-1">2022, Harmet & Co.</span>
      </small>
    </div>
  </v-card>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "Footer",
  data() {
    return {
      footerMenu: [
        "HOME",
        "ABOUT",
        "WHO WE ARE",
        "WHAT WE DO",
        "WHERE WE ARE",
        "CONTACT",
      ],
      languages: [
        { flag: "us", language: "en", title: "EN" },
        { flag: "id", language: "id", title: "ID" },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
ul,
li,
a {
  list-style: none;
  text-decoration: none;
}

button {
  padding: 15px;
  border: 1px solid #c7933e;
  border-radius: 10px;
  font-size: 15px;
  margin: 15px 15px 15px 0;
  color: #ffffff;
}

button:hover {
  background-color: #c7933e;
  color: #ffffff;
}

.footer-wrapped {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: space-around;
  padding: 2em 2em 0;
}

.footer-logo {
  display: flex;
  justify-content: flex-start;
}

.footer-logo-text {
  color: #adadad;
  font-size: 1em;
  text-align: justify;
  padding-right: 5em;
}

/* .footer-center {
  padding-left: 5em;
} */

.title-footer-center {
  font-size: 1.5em;
  font-weight: bolder;
  color: #ffffff;
}

.submenu-footer-center {
  display: flex;
  flex-direction: column;
}

.submenu {
  color: white;
  margin-bottom: 1em;
  color: #c7933e;
}

.submenu:hover {
  color: #debf8c;
}

.title-footer-right {
  font-size: 1.5em;
  font-weight: bolder;
  color: #ffffff;
}

.submenu-footer-right {
  display: flex;
  flex-direction: column;
}

.submenu-right {
  color: white;
  margin-bottom: 1em;
  font-weight: lighter;
}

.footer-bottom-text {
  color: white;
}

/* .footer-text {
  position: absolute;
  left: 15%;
  padding: ;
} */

/* .footer-left {
  display: flex;
  align-items: center;
  flex-direction: column;
} */

.footer-left,
.footer-center,
.footer-right {
  width: 30%;
  height: auto;
}

.button-lang-wrapped {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .footer-wrapped {
    flex-direction: column;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    width: 100%;
  }

  .logo-footer-wrapped {
    display: flex;
    align-items: center;
  }

  .footer-logo-text {
    padding-right: 0;
    margin-left: 9%;
  }

  .footer-center {
    margin: 10% 0;
  }

  .button-lang-wrapped {
    display: flex;
    justify-content: space-evenly;
  }

  .button-lang {
    margin: 15px 0px 15px 0px;
  }
}
</style>
